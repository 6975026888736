import gql from 'graphql-tag'

export const FRAGMENT_PERMISSION = gql`fragment PermissionFull on Permission {
    id
    type
    ability
    feature {
        id
        identifier
        active
        texts {
            identifier
            languagecode
            text
        }
    }
    properties
    texts {
        identifier
        languagecode
        text
    }
}`

export const FRAGMENT_DOMAIN = gql`fragment DomainFull on Domain {
    id
    parent {
        id
        namespace
        texts {
            id
            identifier
            languagecode
            text
        }
    }
    markets
    properties
    identifier
    namespace
    plan {
        identifier
        payment_interval
        texts {
            id
            identifier
            languagecode
            text
        }
    }
    plan_applied_at
    payment_type
    payment_status
    user_limit
    is_demo
    trial_ends_at
    tags {
        id
        category {
            id
            identifier
        }
        identifier
        texts {
            id
            identifier
            text
            languagecode
        }
    }
    texts {
        id
        identifier
        languagecode
        text
    }
    features {
        id
        identifier
        texts {
            identifier
            languagecode
            text
        }
    }
    shared_resources {
        id
        identifier
    }
    deleted_at
}`

export const FRAGMENT_ROLE = gql`fragment RoleFull on Role {
    id
    parent {
        id
        identifier
        texts {
            identifier
            languagecode
            text
        }
    }
    identifier
    domain {
        id
        identifier
    }
    permissions {
        ...PermissionFull
    }
    texts {
        identifier
        languagecode
        text
    }
}${FRAGMENT_PERMISSION}`

export const FRAGMENT_USER_WITHOUT_PERMISSIONS = gql`fragment UserWithoutPermissions on User {
    id
    email
    email_verified_at
    username
    firstname
    lastname
    active
    languagecode
    invitation_sent
    last_login
    is_super
    tfa_enabled
    expires_at
    domains {
        id
        namespace
    }
    tags {
        category {
            id
            identifier
        }
        id
        identifier
        texts  {
            identifier
            languagecode
            text
        }
    }
    roles {
        id
        identifier
        domain {
            id
            identifier
        }
        texts {
            identifier
            languagecode
            text
        }
    }
}`

export const FRAGMENT_USER = gql`fragment UserFull on User {
 id
 email
 firstname
 lastname
 active 
 languagecode
 invitation_sent
 last_login
 is_super
 tfa_enabled
 tags {
     category {
         id
         identifier
     }
     id
     identifier
     texts  {
         identifier
         languagecode
         text
     }
 }
 roles {
    ...RoleFull
 }
}${FRAGMENT_ROLE}`

export const FRAGMENT_SESSION = gql`fragment SessionFull on Session {
    token
    status
    data
    module {
        identifier
        texts {
            identifier
            text
            languagecode
        }
    }
    trackings {
        id
        type
        objective
        site
        score
        status
        key
        data
    }
    tracking_status {
        bookmark
        data
        objectives
        progress
        score
        status
    }
}`
