import Vue from 'vue'
import { Query, Tag } from '../../types/graphql'
import { AVAILABLE_LANGUAGES, AVAILABLE_MARKETS } from '@simpl/access-control/graphql'
import { getTextForUserLanguage } from '../../utils'

export type TagExtended = Tag & {
  name: string
}

export default Vue.extend({
  data () {
    return {
      domainLanguages: [] as TagExtended[],
      domainMarkets: [] as TagExtended[]
    }
  },

  computed: {
    domainId (): string | undefined {
      return this.$store.state.auth.user?.active_domain?.id || this.$store.state.auth.domain?.id
    },
    activeDomainLanguageTags (): TagExtended[] {
      return this.$store.state.auth.domain?.tags.filter((tag: Tag) => tag.category!.identifier === 'language')
        .map((tag: Tag) => ({
          ...tag,
          id: `${tag.id}`,
          name: getTextForUserLanguage(tag)
        })).sort((a: TagExtended, b: TagExtended) => a.name.localeCompare(b.name)) || this.domainLanguages
    },
    activeDomainMarketTags (): TagExtended[] {
      return this.$store.state.auth.domain?.tags.filter((tag: Tag) => tag.category!.identifier === 'market')
        .map((tag: Tag) => ({
          ...tag,
          id: `${tag.id}`,
          name: getTextForUserLanguage(tag)
        })).sort((a: TagExtended, b: TagExtended) => a.name.localeCompare(b.name)) || this.domainMarkets
    }
  },

  apollo: {
    domainLanguages: {
      query: AVAILABLE_LANGUAGES,

      skip (): boolean {
        return !this.domainId || !!this.$store.state.auth.domain?.tags.length
      },

      variables (): object {
        return {
          domain_id: this.domainId
        }
      },

      update (result: Query): TagExtended[] {
        return result.availableLanguages!.map((tag: Tag) => ({
          ...tag,
          name: getTextForUserLanguage(tag)
        }))
      },

      error (error: Error) {
        console.error(error)
      }
    },
    domainMarkets: {
      query: AVAILABLE_MARKETS,

      skip (): boolean {
        return !this.domainId || !!this.$store.state.auth.domain?.tags.length
      },

      variables (): object {
        return {
          domain_id: this.domainId
        }
      },

      update (result: Query): TagExtended[] {
        return result.availableMarkets!.map((tag: Tag) => ({
          ...tag,
          name: getTextForUserLanguage(tag)
        }))
      },

      error (error: Error) {
        console.error(error)
      }
    }
  }
})
