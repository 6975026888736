let storeDB: IDBDatabase

export default {
  async getStoreDB (): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (storeDB) return resolve(storeDB)

      const request = window.indexedDB.open('store', 1)

      request.onerror = (e) => reject(e)

      request.onsuccess = (e) => {
        resolve((storeDB = (e!.target! as any).result))
      }

      request.onupgradeneeded = (e) => {
        const indexedDB = (e!.target! as any).result

        indexedDB.createObjectStore('state', { autoIncrement: true, keyPath: '__module__' })
      }
    })
  },

  async setModuleState (state: Record<string, any>, key: string): Promise<void> {
    const db = await this.getStoreDB()

    return new Promise(resolve => {
      const objectStore = db.transaction('state', 'readwrite').objectStore('state')
      const cursor = objectStore.openCursor(key)

      cursor.onsuccess = (e: Event) => {
        if (!(e.target as any).result) {
          objectStore.add(state)
        } else {
          objectStore.put(state)
        }
        resolve()
      }
    })
  },

  async removeModuleState (key: string): Promise<void> {
    const db = await this.getStoreDB()

    return new Promise(resolve => {
      const objectStore = db.transaction('state', 'readwrite').objectStore('state')
      objectStore.delete(key).onsuccess = () => resolve()
    })
  },

  async getModuleState (key: string): Promise<Record<string, any>> {
    const db = await this.getStoreDB()

    return new Promise(resolve => {
      const objectStore = db.transaction('state', 'readonly').objectStore('state')

      objectStore.get(key).onsuccess = (e: Event) => {
        resolve((e.target as any).result)
      }
    })
  }
}
