import { ACTIONS } from './consts'
import { ActionTree } from 'vuex'
import storeDB from './storeDB'
import store from './index'

export default {
  [ACTIONS.INIT_STORE]: async ({ state }: any) => {
    const storageValue = await storeDB.getModuleState('root')
    if (storageValue) {
      store.replaceState({ ...store.state, ...storageValue })
    }

    for (const key in state) {
      const propType = typeof state[key]
      if (
        propType === 'string' || propType === 'boolean' || propType === 'number' ||
          state[key] === null || state[key] === undefined
      ) { continue }

      const storageValue = await storeDB.getModuleState(key)

      if (storageValue) {
        store.replaceState({
          ...store.state,
          [key]: { ...((store.state as any)[key] || {}), ...storageValue }
        })
      }
    }
  }
} as ActionTree<any, any>
