































































































import mixins from 'vue-typed-mixins'
import PDFViewer from '../pdf/PDFViewer.vue'
import { Tag } from '../../types/graphql'
import ActiveDomainTags from '../../mixins/apollo/ActiveDomainTags'

export default mixins(ActiveDomainTags).extend({
  name: 'LegalDisclosureDialog',

  components: { PDFViewer },

  model: {},

  props: {
    headline: String,
    fullscreen: Boolean,
    hideHeader: Boolean,
    hideActions: Boolean,
    transition: String,
    value: Boolean,
    openedByRoute: Boolean,
    loading: Boolean
  },

  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL || '',
      contentLoaded: false,
      internalShow: Boolean(this.openedByRoute),
      selectedLanguage: 'de'
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.internalShow || this.value
      },
      set (v: boolean) {
        this.internalShow = v
        this.$emit('input', v)
      }
    },
    selectableLanguages (): Tag[] {
      return this.activeDomainLanguageTags.filter(lang => ['de', 'en'].includes(lang.identifier))
    },
    overwriteLegalPdfDe (): String | undefined {
      return process.env.VUE_APP_OVERWRITE_LEGAL_PDF_DE_URL
    },
    overwriteLegalPdfEn (): String | undefined {
      return process.env.VUE_APP_OVERWRITE_LEGAL_PDF_EN_URL
    }
  },

  watch: {
    show (v) {
      if (!v) {
        if (this.openedByRoute) {
          if (this.$route.name?.startsWith('login.')) {
            this.$router.push('/login')
          } else if (this.$route.name?.startsWith('demo.')) {
            this.$router.push('/demo')
          } else {
            this.$router.push('/')
          }
        } else {
          this.$emit('closed')
        }
      } else {
        this.$emit('open')
      }
    },
    content () {
      this.contentLoaded = false
    }
  },

  methods: {
    open ({ click }: { click: (event: Event) => void }) {
      return {
        click (event: Event) {
          return setTimeout(() => click(event))
        }
      }
    }
  }
})
