import store from '@simpl/core/plugins/store'
import { CreateTextInput, Tag, Text } from '../types/graphql'
import { browserLanguageShort } from './env'

export function hasTextForLanguage (
  item: any, language: string, key: string = 'display_name'
): boolean {
  const texts = item?.texts || []
  const textElement = _getTextForLanguage(texts, key, language)
  return !!(textElement && textElement.text)
}

export function getTextForLanguage (
  item: any,
  language: string,
  key: string = 'display_name',
  fallback: string = ''
): string {
  const texts = item?.texts || []
  const textElement = _getTextForLanguage(texts, key, language)
  return textElement ? textElement.text : fallback
}

export function getTextForLanguageWithFallback (
  item: any,
  language: string,
  fallback: string,
  key: string = 'display_name'
): string {
  const texts = item?.texts || []
  let textElement = _getTextForLanguage(texts, key, language)
  if (textElement) {
    return textElement.text
  }
  textElement = _getTextForLanguage(texts, key, fallback)
  return textElement ? textElement.text : ''
}

export function getTextForUserLanguage <F extends boolean = true> (
  item: any, key: string = 'display_name', fallback: F = true as any
): F extends true ? string : string | void {
  const language = store.state.auth?.user?.languagecode || browserLanguageShort
  const marketTags = store.state.auth?.user?.tags?.filter((tag: Tag) => tag.category!.identifier === 'market')
  const marketLanguage = store.state.auth.domain?.markets && marketTags?.length
    ? `${language}-${marketTags[0].identifier}`
    : language
  const fallbackLanguage = item?.languagecode || 'en'
  const texts = item?.texts || []
  const textElement = _getTextForLanguage(texts, key, marketLanguage) ||
    _getTextForLanguage(texts, key, language) ||
    _getTextForLanguage(texts, key, fallbackLanguage) ||
    _getTextForUser(texts, key)

  return textElement ? textElement.text : (fallback ? key : undefined) as any
}

export function getLanguagecodeForTemporaryUserLanguage <F extends boolean = true> (
  item: any, key: string = 'display_name'
): F extends true ? string : string | void {
  const language = store.state.auth.temporaryLanguage
  const texts = item?.texts || []
  const textElement = language ? _getTextForLanguage(texts, key, language) : undefined
  if (textElement) {
    return language
  }
  const userLanguage = store.state.cms?.masterLanguageCode || store.state.auth?.user?.languagecode || browserLanguageShort
  const fallbackLanguage = 'en'
  return _getTextForLanguage(texts, key, userLanguage) ? userLanguage : _getTextForLanguage(texts, key, fallbackLanguage) ? fallbackLanguage : undefined
}

export function getTextForTemporaryUserLanguage <F extends boolean = true> (
  item: any, key: string = 'display_name', fallback: F = true as any
): F extends true ? string : string | void {
  let language = store.state.auth.temporaryLanguage || store.state.auth.user.languagecode
  const marketTags = store.state.auth?.user?.tags?.filter((tag: Tag) => tag.category!.identifier === 'market')
  const marketLanguage = store.state.auth.domain?.markets && marketTags?.length
    ? `${language}-${marketTags[0].identifier}`
    : language
  const texts = item?.texts || []
  let textElement = marketLanguage ? _getTextForLanguage(texts, key, marketLanguage) : undefined
  textElement = !textElement && language ? _getTextForLanguage(texts, key, language) : textElement
  if (textElement) {
    return textElement.text
  }
  language = store.state.cms?.masterLanguageCode || undefined
  if (!language) {
    language = item?.languagecode || undefined
  }
  textElement = language ? _getTextForLanguage(texts, key, language) : undefined
  return textElement ? textElement.text : getTextForUserLanguage(item, key, fallback) as any
}

function _getTextForLanguage (texts: Text[], key: string, language: string): Text | void {
  return texts.find(t => t.languagecode === language && t.identifier === key)
}

function _getTextForUser (texts: Text[], key: string) {
  const tags: Tag[] = (store.state.auth.user.tags || []) as any
  const languages = tags.filter(t => t.category!.identifier === 'language').map(t => t.identifier)

  return texts.find(t => languages.includes(t.languagecode!) && t.identifier === key)
}

export function createTextWithFallback (text: string, target: any = null, identifier: string = 'display_name', language: string = store.state.auth.user.languagecode): CreateTextInput[] {
  const fallback = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'

  const texts: CreateTextInput[] = [{
    text,
    identifier,
    languagecode: language
  }]

  if (store.state.auth.user.languagecode !== fallback && (!target || !hasTextForLanguage(target, fallback))) {
    texts.push({
      text,
      identifier,
      languagecode: fallback
    })
  }

  return texts
}

export function updateTargetTexts (
  target: any,
  text: string,
  identifier: string = 'display_name',
  language: string = store.state.auth.user.languagecode
): void {
  const texts: Text[] = target.texts || (target.texts = [])

  const oldTextItem = _getTextForLanguage(texts, identifier, language)

  if (!text) {
    const textIndex = texts.findIndex(text => text.id === (oldTextItem as Text).id)

    texts.splice(textIndex, 1)
    return
  }

  if (oldTextItem) {
    oldTextItem.text = text
  } else {
    texts.push({
      text,
      identifier,
      languagecode: language
    } as any)
  }

  target._textChangedByUser = true
}

export function nameToIdentifier (name: string) {
  return name
    .replace(/[^0-9a-z-_ ]/gi, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const capitalize = (v: string) => v.charAt(0).toUpperCase() + v.slice(1)

export function truncateIfNecessary (text:string, maxLength: number): string {
  if (maxLength > 4 && text.length > maxLength) {
    return `${text.substr(0, maxLength - 3)}...`
  }
  return text
}
