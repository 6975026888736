import { Module, ModuleOptions, Store } from 'vuex'
import storeDB from './storeDB'
import { debounce } from 'lodash'

const queue: ({ key: string, value: any })[] = []

const update = debounce(async () => {
  for (let i = 0; i < queue.length; i++) {
    const { key, value } = queue[i]
    if (value) {
      await storeDB.setModuleState(value, key)
    } else {
      await storeDB.removeModuleState(key)
    }
  }
}, 50)

export function scheduleUpdate (key: string, value: any) {
  const oldIndex = queue.findIndex(item => item.key === key)
  if (oldIndex > -1) {
    queue.splice(oldIndex, 1)
  }

  queue.push({ key, value })

  update()
}

export function createPersistentStorage (): ((store: Store<any>) => void) {
  return (store: Store<any>) => {
    store.subscribe(async (mutation, state) => {
      let rootState: Record<string, any> | null = null
      const savedState = { ...state }

      for (const key in savedState) {
        if (key.startsWith('_')) continue

        const propType = typeof savedState[key]
        if (
          propType === 'string' || propType === 'boolean' || propType === 'number' ||
          savedState[key] === null || savedState[key] === undefined
        ) {
          rootState = rootState || {}
          rootState[key] = savedState[key]
          continue
        }

        const savedStateForKey = { ...savedState[key] }
        for (const prop in savedStateForKey) {
          if (prop.startsWith('_')) delete savedStateForKey[prop]
        }

        scheduleUpdate(key, { ...savedStateForKey, __module__: key })
      }

      scheduleUpdate('root', { ...rootState, __module__: 'root' })
    })
  }
}
