import Vue from 'vue'
import VueUI from 'vuetify/lib'
import i18n from '../i18n'
import { DomainTheme, DomainThemeImages } from '../../types/domainTheme'

Vue.use(VueUI, {})

const domainTheme: DomainTheme = window.DOMAIN_THEME || {} as any
const domainThemeColors = {
  ...{
    light: {},
    dark: {}
  },
  ...domainTheme.colors || {}
}

const ui = new VueUI({
  icons: {
    values: {
      menu: 'mdi-view-dashboard'
    }
  },
  theme: {
    themes: {
      light: {
        ...{
          primary: '#fc564d',
          secondary: '#fc564d',
          accent: '#fc564d',
          error: '#fc3557',
          info: '#2196f3',
          success: '#64c646',
          warning: '#FFC107'
        },
        ...domainThemeColors.light
      },
      dark: {
        ...{
          primary: '#fc564d',
          secondary: '#fc564d',
          accent: '#fc564d',
          error: '#fc3557',
          info: '#2196f3',
          success: '#64c646',
          warning: '#FFC107'
        },
        ...domainThemeColors.dark
      }
    },
    dark: typeof domainTheme.dark !== 'undefined' ? domainTheme.dark : true
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string
  }
})

export default ui

Vue.prototype.$domainTheme = window.DOMAIN_THEME
Object.defineProperty(Vue.prototype, '$domainImages', {
  get () {
    const images = window.DOMAIN_THEME?.images || {}
    return images[ui.framework.theme.dark ? 'dark' : 'light'] || {}
  }
})

declare module 'vue/types/vue' {
  interface Vue {
    $domainTheme: DomainTheme
    $domainImages: DomainThemeImages
  }
}
