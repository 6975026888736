import gql from 'graphql-tag'
import {
  FRAGMENT_PERMISSION,
  FRAGMENT_ROLE,
  FRAGMENT_USER,
  FRAGMENT_DOMAIN,
  FRAGMENT_USER_WITHOUT_PERMISSIONS
} from '@simpl/core/graphql/fragments'

export const LIST_FEATURES = gql`query LIST_FEATURES (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    features (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            identifier
            texts {
                identifier
                languagecode
                text
            }
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const LIST_ROLES = gql`query LIST_ROLES (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    roles (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            ...RoleFull
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}
${FRAGMENT_ROLE}`

export const LIST_ROLES_MINIMAL = gql`query LIST_ROLES_MINIMAL (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    roles (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            identifier
            texts {
                identifier
                languagecode
                text
            }
        }
    }
}`

export const LIST_DOMAINS = gql`query Domains (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    domains (filter: $filter, first: $first, page: $page, orderBy: $orderBy, trashed: WITH) {
        data {
            ...DomainFull
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}
${FRAGMENT_DOMAIN}`

export const SHOW_DOMAIN = gql`query Domain ($id: ID!) {
    domain (id: $id) {
...DomainFull
    }
}
${FRAGMENT_DOMAIN}`

export const AVAILABLE_MARKETS = gql`query availableMarkets ($domain_id: ID!) {
    availableMarkets (domain_id: $domain_id) {
        id
        identifier
        texts {
            id
            identifier
            languagecode
            text
        }
    }
}`

export const AVAILABLE_LANGUAGES = gql`query availableLanguages ($domain_id: ID!) {
    availableLanguages (domain_id: $domain_id) {
        id
        identifier
        texts {
            id
            identifier
            languagecode
            text
        }
        category {
            id
            identifier
            texts {
                id
                identifier
                languagecode
                text
            }
        }
    }
}`

export const DELETE_DOMAIN = gql`mutation deleteDomain ($id: ID!) {
    deleteDomain(id: $id) {
        id
    }
}`

export const ERASE_DOMAIN_WITH_DEPENDENCIES = gql`mutation eraseDomainWithDependencies ($id: ID!) {
    eraseDomainWithDependencies(id: $id) {
        id
    }
}`

export const RESTORE_DOMAIN = gql`mutation restoreDomain ($id: ID!) {
    restoreDomain(id: $id) {
        id
    }
}`

export const CREATE_DOMAIN = gql`mutation createDomain ($data: NewDomainInput!) {
    createDomain(data: $data) {
        id
    }
}`

export const UPDATE_DOMAIN = gql`mutation updateDomain ($data: UpdateDomainInput!) {
    updateDomain(data: $data) {
        id
        tags {
            id
            category {
                id
                identifier
            }
            identifier
        }
        texts {
            id
            identifier
            languagecode
            text 
        }
    }
}`

export const LIST_PLANS = gql`query Plans (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    plans (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            identifier
            sorting
            head
            feature_set
            admin_quota
            creator_quota
            participant_quota
            run_quota
            run_participant_quota
            course_quota
            storage_quota
            payment_interval
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const DOMAIN_QUOTAS = gql`query DomainQuotas {
    domainQuotas {
        roles {
            type
            quota
            available
        }
        runs {
            quota
            available
        }
        run_participants {
            quota
            available
        }
        courses {
            quota
            available
        }
        storage {
            quota
            available
        }
    }
}`

export const DOMAIN_STORAGE_QUOTA = gql`query DomainStorageQuotas {
    domainQuotas {
        storage {
            quota
            available
        }
    }
}`

export const LIST_USERS = gql`query Users (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!],
    $orderByRelation: OrderByRelationClause 
) {
    users (filter: $filter, first: $first, page: $page, orderBy: $orderBy, orderByRelation: $orderByRelation) {
      data {
        ...UserWithoutPermissions
      }
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
  }
}
${FRAGMENT_USER_WITHOUT_PERMISSIONS}`

export const LIST_USERS_MINIMAL = gql`query UsersMinimal (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!],
    $orderByRelation: OrderByRelationClause 
) {
    users (filter: $filter, first: $first, page: $page, orderBy: $orderBy, orderByRelation: $orderByRelation) {
      data {
        id
        email
        firstname
        lastname
        is_super
      }
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
  }
}`

export const LIST_PENDING_USERS = gql`query PendingUsers (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    pendingUsers (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            email
            firstname
            lastname
            languagecode
            market {
                id
                identifier
                texts {
                    id
                    identifier
                    languagecode
                    text
                }
            }
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const APPROVE_PENDING_USERS = gql`mutation ApprovePendingUsers ($data: ApprovePendingUsersInput!) {
    approvePendingUsers (data: $data) {
        id
    }
}`

export const DELETE_PENDING_USER = gql`mutation DeletePendingUser ($id: ID!) {
    deletePendingUser (id: $id) {
        id
    }
}`

export const LIST_SUPPORT_NOTIFIABLES = gql`query SupportNotifiables (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    supportNotifiables (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
      data {
        ...UserWithoutPermissions
      }
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
  }
}
${FRAGMENT_USER_WITHOUT_PERMISSIONS}`

export const ADD_ROLE = gql`mutation createRole ($data: NewRoleInput!) {
    createRole(data: $data) {
        id
        identifier
    }
}`

export const CREATE_USER = gql`mutation createUser ($data: NewUserInput!) {
    createUser(data: $data) {
        id
    }
}`

export const UPDATE_ROLE = gql`mutation updateRole ($data: UpdateRoleInput!) {
    updateRole(data: $data) {
        id
        identifier
    }
}`

export const UPDATE_USER = gql`mutation updateUser ($data: UpdateUserInput!) {
    updateUser(data: $data) {
        id
    }
}`

export const GET_USER = gql`query user($id: ID!) {
  user(id: $id) {
      ...UserFull
  }
}
${FRAGMENT_USER}`

export const GET_USER_MINIMAL = gql`query userMinimal($id: ID!) {
  user(id: $id) {
     id
     email
     firstname
     lastname
  }
}`

export const DELETE_ROLE = gql`mutation deleteRole ($id: ID!) {
    deleteRole(id: $id) {
        id
    }
}
${FRAGMENT_ROLE}`

export const UPDATE_TAG = gql`mutation updateTag ($data: UpdateTagInput!) {
    updateTag(data: $data) {
        id
        identifier
    }
}`

export const CREATE_TAG = gql`mutation createTag ($data:  NewTagInput!) {
    createTag(data: $data) {
        id
        identifier
    }
}`

export const DELETE_TAG = gql`mutation deleteTag ($id: ID!) {
    deleteTag(id: $id) {
        id
        identifier
    }
}`

export const TAGS = gql`query tags (
    $filter: FilterInput!,
    $created_at: DateRange,
    $orderBy: [OrderByClause!],
    $first: Int!,
    $page: Int
)   {
    tags (
        filter: $filter,
        created_at: $created_at,
        orderBy: $orderBy,
        first: $first,
        page: $page
        ) {
        data {
            id
            category {
                id
            }
            identifier
            texts {
                id
                identifier
                languagecode
                text
            }
        }
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}`

export const DELETE_USER = gql`mutation detachOrDeleteUser ($id: ID!, $force: Boolean) {
    detachOrDeleteUser(id: $id, force: $force) {
      id
      firstname
      lastname
    }
}`

export const SET_USER_ROLE_RELATIONS = gql`mutation setUserRoleRelations ($data: AccessControlUserRelationsInput!) {
    updateAccessControlUserRelations(data: $data) {
        ...UserFull
    }
}
${FRAGMENT_USER}`

export const LIST_PERMISSIONS = gql`query Permissions {
    permissions (first: 999999, filter: {}) {
        data {
            ...PermissionFull
        }
    }
}
${FRAGMENT_PERMISSION}`

export const USER_EXISTS = gql`query userExists ($type: String!, $query: String!) {
    userExists (type: $type, query: $query) {
        exists
        user {
            ...UserFull
        }
        status
    }
}
${FRAGMENT_USER}`

export const NAMESPACE_EXISTS = gql`query namespaceExists ($query: String!) {
    namespaceExists (query: $query)
}`

export const INVITE = gql`mutation invite ($data: InviteInput!) {
    invite (data: $data) {
      status
      id
      email
      invitation_sent
    }
}`

export const NOTIFY_ALLOCATED = gql`mutation notifyAllocated ($data: InviteInput!) {
    notifyAllocated (data: $data) {
      status
      id
      email
      invitation_sent
    }
}`

export const SET_ACTIVE_DOMAIN = gql`mutation setActiveDomain ($domain_id: ID!) {
    setActiveDomain (domain_id: $domain_id)
}`

export const USER_TRACKING_HISTORY = gql`query userTrackingHistory (
    $user_id: ID!
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!],
    $orderByRelation: OrderByRelationClause     
) {
    userTrackingHistory (
      user_id: $user_id, 
      filter: $filter, 
      first: $first, 
      page: $page, 
      orderBy: $orderBy, 
      orderByRelation: $orderByRelation
    ) {
        data {
          run {
            id
            type
            status
            texts {
              identifier
              languagecode
              text
            }
          }
          score
          status
          progress
          started_at
          completed_at
        }
        paginatorInfo {
          count
          currentPage
          hasMorePages
          lastPage
          perPage
          total
        }
    }
}`

export const IMPORT_USERS = gql`mutation importUsers (
    $data: ImportUsersInput!
) {
    importUsers (data: $data) {
        success
        message
    }
}`
