














































































import Vue from 'vue'
import PDFViewer from '../pdf/PDFViewer.vue'

const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

export default Vue.extend({
  name: 'ContentDialog',

  components: { PDFViewer },

  model: {},

  props: {
    headline: String,
    content: String,
    fullscreen: Boolean,
    hideHeader: Boolean,
    hideActions: Boolean,
    transition: String,
    value: Boolean,
    openedByRoute: Boolean,
    loading: Boolean,
    isPdf: Boolean
  },

  data () {
    return {
      contentLoaded: false,
      internalShow: Boolean(this.openedByRoute)
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.internalShow || this.value
      },
      set (v: boolean) {
        this.internalShow = v
        this.$emit('input', v)
      }
    },
    isUrl (): boolean {
      return urlPattern.test(this.content) && !this.isPdf
    }
  },

  watch: {
    show (v) {
      if (!v) {
        if (this.openedByRoute) {
          if (this.$route.name?.startsWith('login.')) {
            this.$router.push('/login')
          } else if (this.$route.name?.startsWith('demo.')) {
            this.$router.push('/demo')
          } else {
            this.$router.push('/')
          }
        } else {
          this.$emit('closed')
        }
      } else {
        this.$emit('open')
      }
    },
    content () {
      this.contentLoaded = false
    }
  },

  methods: {
    open ({ click }: { click: (event: Event) => void }) {
      return {
        click (event: Event) {
          return setTimeout(() => click(event))
        }
      }
    }
  }
})
