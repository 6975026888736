import { MutationTree } from 'vuex'
import { MUTATIONS } from './consts'
import { Tour } from '../../components/GuidedTour.vue'

export default {
  forcePersist: () => {},
  [MUTATIONS.SET_COOKIE_CONSENT_ACCEPTED]: (state, payload) => {
    state.cookieConsentAccepted = payload
  },
  [MUTATIONS.SET_GUIDED_TOUR]: (state, payload: { id: string, tours?: Tour[] }) => {
    state._guidedTourId = payload?.id || undefined!
    state._guidedTours = payload?.tours || []
  },
  [MUTATIONS.SET_USER_DROPDOWN]: (state, payload) => {
    state._userDropdown = payload
  },
  [MUTATIONS.FORCE_USER_DROPDOWN]: (state, payload) => {
    state._forceUserDropdown = payload
  },
  [MUTATIONS.SET_AVAILABLE_LANGUAGES]: (state, payload) => {
    state._availableLanguages = payload
  },
  [MUTATIONS.SET_APP_ID]: (state, payload) => {
    state._appId = payload
  },
  [MUTATIONS.SET_BOOTED]: (state, payload) => {
    state._booted = payload
  }
} as MutationTree<any>
