import Vue_, { VueConstructor } from 'vue'

export interface InjectOptions<T> {
  from?: string,
  default: T
}

export type Accessors<T> = {
  [K in keyof T]: InjectOptions<T[K]>
}

type DefaultInject = { [key: string]: InjectOptions<any> }

// eslint-disable-next-line import/export
export default function inject<T = DefaultInject> (
  injects: Accessors<T>
): VueConstructor<Vue_ & T>
// eslint-disable-next-line import/export
export default function inject <T = DefaultInject> (injects: Accessors<T>): VueConstructor<Vue_ & T> {
  return Vue_.extend({ inject: injects }) as any
}
