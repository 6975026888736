



















import Vue from 'vue'
import PDF from 'vue-pdf'
import * as PDFJS from 'pdfjs-dist'
import { debounce } from '../../utils'

export default Vue.extend({
  components: {
    PDF
  },

  props: {
    src: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      loaded: false,
      textDivs: [] as HTMLElement[]
    }
  },

  methods: {
    queueRenderTextLayer: debounce(function (this: any) {
      this.renderTextLayer()
    }, 500),

    async renderTextLayer () {
      if (!this.loaded) return

      this.textDivs.forEach(el => {
        el.parentElement!.removeChild(el)
      })
      this.textDivs = []

      const pdfDoc = await this.src.promise
      const page = await pdfDoc.getPage(this.page)
      const textContent = await page.getTextContent()
      const renderer = this.$refs.pdfRenderer as Vue
      const canvas = renderer.$el.querySelector('canvas') as HTMLCanvasElement
      const defaultViewport = await page.getViewport({ scale: 1.0 })

      const viewport = await page.getViewport({ scale: canvas.width / defaultViewport.width })

      const renderTextLayerTask = PDFJS.renderTextLayer({
        textContent,
        container: this.$refs.textLayer as HTMLElement,
        viewport,
        textDivs: this.textDivs
      })

      await renderTextLayerTask.promise
    },

    async onLoaded () {
      this.loaded = true
      await this.renderTextLayer()
      this.$emit('loaded')
    }
  }
})
