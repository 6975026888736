export const isDev = process.env.NODE_ENV !== 'production'
export const IE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1
export const safari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)
export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
export const browserLanguage: string = navigator.language || (navigator as any).userLanguage
export const browserLanguageShort = browserLanguage && browserLanguage.split('-')[0]

export const namespacesWithDemo: string[] = (process.env.VUE_APP_NAMESPACES_WITH_DEMO || '').split(',')
export const subdomain = location.hostname.split(/\./)[0]
export const isDemoAvailableInNamespace = isDev || namespacesWithDemo.includes(subdomain)
