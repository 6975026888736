import i18n from '../i18n/index'

const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\/index.ts$/i)
locales.keys().forEach(key => {
  const matched = key.match(/([A-Za-z0-9-_]+)\/index\.ts/i)
  if (matched && matched.length > 1) {
    const locale = matched[1]
    i18n && i18n.mergeLocaleMessage(locale, locales(key).default)
  }
})

if (module.hot) {
  module.hot.accept()
}
