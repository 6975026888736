import i18n from '../plugins/i18n'
import ContentDialog from '../components/dialogs/ContentDialog.vue'
import ui from '@simpl/core/plugins/ui'
import LegalDisclosureDialog from '../components/dialogs/LegalDisclosureDialog.vue'
import { getLegalDisclosureContent } from './get-legal-disclosure-content'

export const generateDialogRoutes = (component: any, meta: Record<string, any>, prefix?: string) => {
  const routes = [{
    name: 'imprint'
  }, {
    name: 'legal',
    // content: `https://dev-api.simpl.training/agb/${i18n.locale}`,
    isPdf: true
  }, {
    name: 'privacy'
  }]

  return routes.map(r => ({
    path: prefix ? `/${prefix}/${r.name}` : `/${r.name}`,
    name: prefix ? `${prefix}.${r.name}` : r.name,
    meta,
    components: {
      default: component,
      dialog: r.name === 'legal' ? LegalDisclosureDialog : ContentDialog
    },
    props: {
      default: false,
      dialog: () => {
        return {
          headline: i18n.t(`core.global.${r.name}`),
          content: getLegalDisclosureContent(r.name),
          isPdf: r.isPdf,
          fullscreen: ui.framework.breakpoint.smAndDown,
          openedByRoute: true
        }
      }
    }
  }))
}
