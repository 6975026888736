export const ACTIONS = {
  INIT_STORE: 'initStore'
}

export const MUTATIONS = {
  SET_COOKIE_CONSENT_ACCEPTED: 'setCookieConsentAccepted',
  SET_USER_DROPDOWN: 'setUserDropdown',
  FORCE_USER_DROPDOWN: 'forceUserDropdown',
  SET_AVAILABLE_LANGUAGES: 'setAvailableLanguages',

  SET_GUIDED_TOUR: 'setGuidedTour',

  SET_BOOTED: 'setBooted',
  SET_APP_ID: 'setAppId'
}
