import Vue from 'vue'
import VueStore, { Module, ModuleOptions } from 'vuex'
import { createPersistentStorage, scheduleUpdate } from './persist'

import state from './state'
import actions from './actions'
import mutations from './mutations'
import storeDB from './storeDB'

Vue.use(VueStore)

const store = new VueStore.Store({
  state,
  mutations,
  actions,
  plugins: [createPersistentStorage()]
})

const oldRegisterModule = store.registerModule
store.registerModule = async function <T, S> (path: string | string[], module: Module<T, S>, options?: ModuleOptions) {
  oldRegisterModule.call(this, path as any, module as any, options)

  const storageValue = await storeDB.getModuleState(path as string)
  if (storageValue) {
    const mergedKeyState = {
      ...(store.state[path as any] || {}),
      ...storageValue
    }

    store.replaceState({
      ...store.state,
      [path as string]: mergedKeyState
    })
  }
}

const oldUnregisterModule = store.unregisterModule
store.unregisterModule = async function <T, S> (path: string | string[]) {
  oldUnregisterModule.call(this, path as any)

  const keys = Array.isArray(path) ? path : [path]
  for (const key of keys) {
    scheduleUpdate(key, null)
  }
}

export default store
