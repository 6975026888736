import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import { browserLanguageShort } from '@simpl/core/utils/env'

Vue.use(VueI18n)

const dateTimeFormats = {} as Record<string, any>

const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\/dateTimeFormats.json/i)
locales.keys().forEach(key => {
  const matched = key.match(/([A-Za-z0-9-_]+)\/dateTimeFormats\.json/i)
  if (matched && matched.length > 1) {
    const locale = matched[1]
    dateTimeFormats[locale] = locales(key).default
  }
})

const i18n = new VueI18n({
  dateTimeFormats,
  messages: {} as LocaleMessages,
  locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
})

export default i18n

require('./locales')

i18n.locale = browserLanguageShort
