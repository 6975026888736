import type { Tour } from '../../components/GuidedTour.vue'
import { Tag } from '../../types/graphql'

export default {
  cookieConsentAccepted: true,

  _booted: false,
  _appId: `SimpL_${+(new Date())}`,
  _userDropdown: false,
  _forceUserDropdown: false,
  _availableLanguages: null as Tag[] | null,

  _guidedTourId: undefined! as string,
  _guidedTours: [] as Tour[]
} as any
