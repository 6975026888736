














































































































import Vue from 'vue'
import PDF from 'vue-pdf'
import PDFPageViewer from './PDFPageViewer.vue'
import printJS from 'print-js'

export default Vue.extend({
  name: 'PDFViewer',

  components: {
    PDFPageViewer
  },

  props: {
    src: String,
    page: {
      type: Number,
      default: 1
    },
    title: String
  },

  data () {
    return {
      loading: true,
      rendering: false,
      progress: 0,
      pdfSrc: null! as any,
      numPages: 0,
      rendered: 0,
      currentPage: 0,

      zoom: 100,
      zooms: [25, 50, 75, 100, 125, 150]
    }
  },

  watch: {
    currentPage (v: number) {
      if (!this.numPages) return

      this.$emit('progress', v / this.numPages)
      this.$emit('page', v)
    },
    page (v: number) {
      this.currentPage = v
      this.$vuetify.goTo(this.$refs[`pageViewer${this.page}`] as any, {
        container: this.$refs.scrollContainer as HTMLElement
      })
    }
  },

  async mounted (): Promise<void> {
    this.pdfSrc = PDF.createLoadingTask(this.src, {
      onProgress: (status: ProgressEvent) => {
        this.progress = status.loaded / status.total * 100
      }
    })

    const pdf = await this.pdfSrc.promise
    this.numPages = pdf.numPages
    this.currentPage = 1

    this.loading = false
    this.progress = 0
    this.rendering = true

    this.$emit('loaded')
  },

  methods: {
    onRender (): void {
      if (this.rendered >= this.numPages) return

      this.rendered += 1
      this.progress = this.rendered / this.numPages * 100

      if (this.rendered === this.page) {
        this.rendering = false
        this.$emit('rendered')

        if (this.page !== 1) {
          this.$nextTick(() => {
            setTimeout(() => {
              const ref: any = this.$refs.viewerContainer ? (this.$refs.viewerContainer as any)[this.page - 1] : null
              this.$vuetify.goTo(ref as any, {
                container: this.$refs.scrollContainer as HTMLElement
              })
            }, 200)
          })
        }
      }
    },

    getIntersectionObserverOptions (page: number): Record<string, any> {
      return {
        handler: (_1: any, _2: any, isIntersecting: boolean) => {
          if (!isIntersecting) return
          this.currentPage = page
        },

        options: {
          root: this.$refs.viewerContainer,
          threshold: 0.20
        }
      }
    },

    print () {
      printJS(this.src)
    },

    goToPage (page: number) {
      const ref: any = this.$refs.viewerContainer ? (this.$refs.viewerContainer as any)[page - 1] : null
      if (ref) {
        this.$vuetify.goTo(ref as any, {
          container: this.$refs.scrollContainer as HTMLElement
        })
      }
    }
  }
})
