import { IE, iOS, safari } from './utils'

export default async function bootSimpL () {
  if (IE) {
    await import(/* webpackChunkName: "polyfills" */ './polyfills')
  }

  const initApp = (await import('./init-app')).default

  const app = await initApp()

  app.$mount('#app')

  if (iOS && safari) {
    applyDoubleTapZoomFix()
    applyIOSHeightFix()
  }
}

// TODO JH: this is an experimental fix, since a double tap on iOS zooms in the interactive element and "destroys" the default interaction
function applyDoubleTapZoomFix () {
  document.ondblclick = function(e) {
    e.preventDefault();
  }
}

function applyIOSHeightFix () {
  const fixStyle = document.createElement('style')
  fixStyle.setAttribute('type', 'text/css')
  fixStyle.setAttribute('id', 'ios-fix')
  document.head.appendChild(fixStyle)

  const setFixedHeight = () => {
    document.documentElement.style.height = `${window.innerHeight}px`
    document.documentElement.style.overflow = 'hidden'
  }

  window.addEventListener('resize', () => {
    window.setTimeout(() => setFixedHeight(), 50)
  })

  setFixedHeight()
}
