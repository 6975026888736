import { Tag } from '../types/graphql'
import { getTextForUserLanguage } from './text'
import lodashDebounce from 'lodash.debounce'
import lodashThrottle from 'lodash.throttle'
import objectHashSum from 'object-hash'

export const isDefined = <T>(value: T | undefined): value is T => {
  return typeof value !== 'undefined' && value != null
}

export const sleep = (ms: number) => new Promise((resolve) => window.setTimeout(resolve, ms))

export const memoize = <T extends Function>(fn: T): T => {
  const cache: Record<string, any> = {}
  const returnFunc = (...args: any[]) => {
    const key = JSON.stringify(args)
    return cache[key] || (cache[key] = fn(...args))
  }
  return returnFunc as any
}

export const debounce = lodashDebounce
export const throttle = lodashThrottle
export const objectHash = objectHashSum

export function convertToUnit (str: string | number, unit = 'px') {
  if (str == null || str === '') {
    return undefined
  } else if (isNaN(+str)) {
    return String(str)
  } else {
    return `${Number(str)}${unit}`
  }
}

const DATE_REGEXP = /^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})$/

export function stringToDate (str: string | null | undefined): Date | null {
  const match = DATE_REGEXP.exec(str || '')
  if (!match) return null
  return new Date(+match[1], +match[2] - 1, +match[3], +match[4], +match[5], +match[6])
}

export function hash (str: string) {
  let hash = 5383
  let i = str.length

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i)
  }

  return hash >>> 0
}

function sumDigits (value: number | string, len: number = 1) {
  let str = String(value)

  while (str.length > len) {
    str = String(str.split('').reduce((acc, v) => acc + parseInt(v), 0))
  }

  return parseInt(str)
}

export function stringTo2DigitId (str: string): number[] {
  return String(sumDigits(hash(str), 2)).split('').map(s => parseInt(s))
}

const COLOR_MATRIX = [
  ['#f2e302', '#fbd700', '#ffc400', '#fec82f', '#fc8550', '#fe7c60', '#fd836d', '#ff9987', '#ff636c', '#ff5353'],
  ['#e0d300', '#d7d027', '#ffb900', '#f3ae42', '#dd9043', '#f16a43', '#fe6060', '#df6e5b', '#f56a79', '#f20017'],
  ['#a1e300', '#d3d03e', '#fba700', '#fe9e21', '#ff5733', '#fe4d27', '#ff414d', '#d84e55', '#f1537c', '#9a4747'],
  ['#46aa6a', '#a3d537', '#febc21', '#ff8000', '#ee5037', '#ec1c4b', '#ff1d58', '#b8424d', '#bf1727', '#971515'],
  ['#0ddb4c', '#53c750', '#8bbc20', '#f7ba00', '#b26518', '#c70039', '#e8175d', '#740022', '#d20014', '#ff003d'],
  ['#00bfa8', '#6fcf73', '#25d366', '#15b455', '#0e773b', '#a1008e', '#a6206a', '#a82e51', '#d2004f', '#f60083'],
  ['#3eb9a8', '#279f8e', '#009788', '#2d975b', '#04a048', '#70206a', '#b62284', '#cc00b4', '#d20093', '#ee00ff'],
  ['#00bcd4', '#08979d', '#008080', '#046e63', '#004f71', '#56158b', '#6a5daf', '#c848b9', '#9b00d2', '#641481'],
  ['#5eade2', '#0e85ba', '#007887', '#003688', '#3c2e88', '#781fbc', '#994be6', '#e458c0', '#6200d2', '#33006e'],
  ['#0080d9', '#0c73a1', '#0862a5', '#0049b7', '#8f509d', '#793a87', '#a977ff', '#9f66a9', '#0e00d2', '#070067']
]

// const COLORS = ['primary', 'primary', 'cyan', 'red', 'indigo', 'purple', 'orange', 'teal', 'pink', 'lime']

export const stringToColor = memoize((str: string) => {
  const digits = stringTo2DigitId(str)
  return COLOR_MATRIX[digits[0] || 0][digits[1] || 0]
})

export function hasOwn <T> (obj: T, key: PropertyKey): key is keyof T {
  return obj ? Object.prototype.hasOwnProperty.call(obj, key) : false
}

export function tagSelectFilter (item: Tag, queryText: string, _: any): boolean {
  const displayName = getTextForUserLanguage(item).toLowerCase()
  const nativeName = getTextForUserLanguage(item, 'native_name').toLowerCase()
  const identifier = item.identifier.toLowerCase()

  return displayName.includes(queryText.toLocaleLowerCase()) ||
    nativeName.includes(queryText.toLocaleLowerCase()) ||
    identifier.includes(queryText.toLocaleLowerCase())
}

export function isObject (obj: any) {
  return typeof obj === 'object' && obj != null
}

export function shuffle (array: any[]): any[] {
  return array.sort(() => 0.5 - Math.random())
}
